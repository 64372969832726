import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { setInlineBackgroundColor, setInlineTextColor } from 'helpers/LandingPageUtils';
import LandingProductCardWrapper from 'components/landing/LandingProductCardWrapper';
import { evHeroWithProductStreamClick, evHeroWithProductStreamImpression } from 'events/symphony';
import { track } from 'apis/amethyst';
import { convertProductLandingToProductWithRelations } from 'helpers/RecoUtils';

import css from 'styles/components/landing/melodyCurated.scss';

export const MelodyCurated = ({ slotName, slotIndex, slotDetails, onComponentClick, slotHeartsData, eventLabel, shouldLazyLoad }) => {
  const { products, title, link, ctacopy, copy, bgcolor, gae, textcolor, flip } = slotDetails;

  const formattedProducts = products.map(product => convertProductLandingToProductWithRelations(product));

  useEffect(() => {
    const defaultEventObj = { slotDetails, slotIndex, slotName };
    const badgesEventObj = { formattedProducts, ...defaultEventObj };

    if (formattedProducts.length) {
      track(() => [evHeroWithProductStreamImpression, badgesEventObj]);
    }
  }, [slotDetails, slotIndex, slotName, formattedProducts.length]);

  const onHeroClick = useCallback(
    evt => {
      onComponentClick && onComponentClick(evt);
      track(() => [evHeroWithProductStreamClick, { slotDetails, slotIndex, slotName }]);
    },
    [onComponentClick, slotDetails, slotIndex, slotName]
  );

  const onProductClick = useCallback(
    (evt, product) => {
      onComponentClick && onComponentClick(evt);
      track(() => [evHeroWithProductStreamClick, { slotDetails, slotIndex, slotName, product }]);
    },
    [onComponentClick, slotDetails, slotIndex, slotName]
  );

  const makeTitle = () => title && <h2 style={setInlineTextColor(textcolor)}>{title}</h2>;

  const makeCopy = () => copy && <p style={setInlineTextColor(textcolor)}>{copy}</p>;

  const makeCallToAction = () => ctacopy && <p className={css.cta}>{ctacopy}</p>;

  const makeMainContent = () => {
    const styling = textcolor === 'white' ? css.mainContentCtaWhite : css.mainContentCtaDefault;
    return (
      <div className={cn(css.mainContentContainer, css.mainContentContainerBadges, styling)} style={setInlineBackgroundColor(bgcolor)}>
        <Link to={link} onClick={onHeroClick} data-eventlabel={eventLabel} data-slotindex={slotIndex} data-eventvalue={gae || title}>
          {makeTitle()}
          {makeCopy()}
          {makeCallToAction()}
        </Link>
      </div>
    );
  };

  return (
    <div className={cn(css.mCurated, css.mCuratedBadges, { [css.flipped]: flip === 'true' })} data-slot-id={slotName}>
      {makeMainContent()}
      <LandingProductCardWrapper
        slotDetails={slotDetails}
        slotHeartsData={slotHeartsData}
        onComponentClick={onProductClick}
        componentStyling={css.productCuratedCard}
        shouldLazyLoad={shouldLazyLoad}
      />
    </div>
  );
};

export default withErrorBoundary('MelodyCurated', MelodyCurated);
