import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import LandingProductCardWrapper from 'components/landing/LandingProductCardWrapper';
import { evSearchProductStreamClick, evSearchProductStreamImpression } from 'events/symphony';
import { track } from 'apis/amethyst';
import { convertProductLandingToProductWithRelations } from 'helpers/RecoUtils';

import css from 'styles/components/landing/productResults.scss';

export const ProductResults = ({ slotName, slotIndex, slotDetails, onComponentClick, slotHeartsData, eventLabel, isFullWidth, shouldLazyLoad }) => {
  const { title, products, gae, ctacopy, ctalink } = slotDetails;

  const formattedProducts = products.map(product => convertProductLandingToProductWithRelations(product));

  useEffect(() => {
    const defaultEventObj = { slotDetails, slotIndex, slotName };
    const badgesEventObj = { formattedProducts, ...defaultEventObj };

    if (formattedProducts.length) {
      track(() => [evSearchProductStreamImpression, badgesEventObj]);
    }
  }, [slotDetails, slotIndex, slotName, formattedProducts.length]);

  const onProductClick = useCallback(
    (evt, product) => {
      onComponentClick?.(evt);
      track(() => [evSearchProductStreamClick, { slotDetails, slotIndex, slotName, product }]);
    },
    [onComponentClick, slotDetails, slotIndex, slotName]
  );

  return (
    <section className={cn(css.container, { [css.contained]: !isFullWidth })} data-slot-id={slotName} data-slotindex={slotIndex}>
      {title && <h2>{title}</h2>}
      <div className={css.products}>
        <LandingProductCardWrapper
          slotDetails={slotDetails}
          slotHeartsData={slotHeartsData}
          onComponentClick={onProductClick}
          shouldLazyLoad={shouldLazyLoad}
        />
      </div>
      {ctacopy && ctalink && (
        <Link data-eventlabel={eventLabel} data-eventvalue={gae || ctacopy} className={css.cta} onClick={onComponentClick} to={ctalink}>
          {ctacopy}
        </Link>
      )}
    </section>
  );
};

ProductResults.propTypes = {
  slotName: PropTypes.string,
  slotIndex: PropTypes.number,
  slotDetails: PropTypes.object.isRequired,
  onComponentClick: PropTypes.func,
  slotHeartsData: PropTypes.object,
  shouldLazyLoad: PropTypes.bool
};

export default withErrorBoundary('ProductResults', ProductResults);
