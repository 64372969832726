import { useCallback, useEffect } from 'react';

import SmallProductCard from 'components/common/SmallProductCard';
import { evSearchProductStreamClick, evSearchProductStreamImpression } from 'events/symphony';
import { track } from 'apis/amethyst';
import useMartyContext from 'hooks/useMartyContext';
import Carousel from 'components/common/Carousel/Carousel';

import css from 'styles/components/common/productCarousel.scss';

const imgDimensions = 211;
const type = 'flexv';

const ProductCarousel = ({ slotName, slotDetails, onProductClick = null, slotIndex }) => {
  const { testId } = useMartyContext();
  const { products, title } = slotDetails;

  useEffect(() => {
    track(() => [evSearchProductStreamImpression, { slotDetails, slotIndex, slotName }]);
  }, [slotDetails, slotIndex, slotName]);

  const onProductCardClick = useCallback(
    (evt, product) => {
      onProductClick && onProductClick(evt, product);
      track(() => [evSearchProductStreamClick, { slotDetails, slotIndex, slotName, product }]);
    },
    [onProductClick, slotDetails, slotIndex, slotName]
  );

  return (
    <div data-test-id={testId('productCarousel')} className={css.productCarousel} data-slot-id={slotName}>
      <Carousel
        slides={products.map((product, i) => (
          <SmallProductCard
            index={i}
            key={`${product.styleId}-${i}`}
            cardData={product}
            onClick={onProductCardClick}
            type={type}
            msaImageDimensions={imgDimensions}
            threeSixtyDimensions={imgDimensions}
          />
        ))}
        header={{ title: title }}
      />
    </div>
  );
};

export default ProductCarousel;
