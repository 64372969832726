import type { MouseEvent, MouseEventHandler } from 'react';
import React, { useEffect } from 'react';

import type { ProductWithStyles } from 'constants/searchTypes';
import ProductCard from 'components/common/ProductCard';
import Scroller from 'components/common/Scroller';
import HtmlToReact from 'components/common/HtmlToReact';
import useMartyContext from 'hooks/useMartyContext';
import { evSearchCrossSiteRecoClick, evSearchCrossSiteRecoImpression, evSearchCrossSiteRecoInteraction } from 'events/recommendations';

import css from 'styles/components/search/marketRecos.scss';

interface Props {
  crossSiteRecos: any;
}

const MarketRecos = ({ crossSiteRecos }: Props) => {
  const { amethystTrack, testId, trackEvent } = useMartyContext();
  const { siteName, products, ctacopy, title, copy, executedSearchUrl, creativeID, target = '' } = crossSiteRecos;
  const linkProps = { target };
  const widgetType = 'CROSS_SITE_WIDGET';

  const cards = products.map((product: ProductWithStyles) => {
    const cardClick = () => {
      amethystTrack(() => [
        evSearchCrossSiteRecoClick,
        {
          product: { ...product, crossSiteSellingUniqueIdentifier: creativeID },
          widgetType
        }
      ]);
    };

    return (
      <li key={`${product.styleId}-${product.colorId}`}>
        <ProductCard {...product} showRatingStars={false} onClick={cardClick} linkProps={linkProps} />
      </li>
    );
  });

  const viewAllClick = () => {
    amethystTrack(() => [
      evSearchCrossSiteRecoInteraction,
      {
        products,
        widgetType,
        interaction: 'VIEW_ALL',
        uniqueIdentifier: creativeID
      }
    ]);
  };

  const makeArrowClickHandler = (callback: MouseEventHandler, isLeft?: boolean) => {
    const interaction = isLeft ? 'PREVIOUS_ARROW' : 'NEXT_ARROW';
    return (e: MouseEvent) => {
      amethystTrack(() => [evSearchCrossSiteRecoInteraction, { products, widgetType, interaction, uniqueIdentifier: creativeID }]);
      callback(e);
    };
  };

  const ctaUrl = !!executedSearchUrl && `https://${siteName}.com${executedSearchUrl}`;

  useEffect(() => {
    amethystTrack(() => [evSearchCrossSiteRecoImpression, { products, uniqueIdentifier: creativeID, widgetType }]);
  }, [amethystTrack, trackEvent, creativeID, products]);

  return (
    <>
      <HtmlToReact>{title}</HtmlToReact>
      <HtmlToReact className={css.subtitle} containerEl="p">
        {copy}
      </HtmlToReact>
      <Scroller>
        {({ contentRef, leftArrowClick, rightArrowClick, scrollLeftDisabled, scrollRightDisabled }) => (
          <section className={css.wrapper} data-test-id={testId('marketRecosContainer')}>
            <button type="button" className={css.leftArrow} disabled={scrollLeftDisabled} onClick={makeArrowClickHandler(leftArrowClick, true)}>
              Scroll Left
            </button>
            <ul ref={contentRef}>{cards}</ul>
            <button type="button" className={css.rightArrow} disabled={scrollRightDisabled} onClick={makeArrowClickHandler(rightArrowClick)}>
              Scroll Right
            </button>
          </section>
        )}
      </Scroller>
      {ctacopy && ctaUrl ? (
        <div className={css.ctaWrapper}>
          <a href={ctaUrl} onClick={viewAllClick} target={target}>
            {ctacopy}
          </a>
        </div>
      ) : null}
    </>
  );
};

export default MarketRecos;
