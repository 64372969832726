import { useCallback, useEffect } from 'react';

import { cn } from 'helpers/classnames';
import LandingProductCardWrapper from 'components/landing/LandingProductCardWrapper';
import LandingPageImage from 'components/landing/LandingPageImage';
import LandingPageLink from 'components/landing/LandingPageLink';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { setInlineBackgroundColor, setInlineTextColor } from 'helpers/LandingPageUtils';
import { evHeroWithProductStreamImpression } from 'events/symphony';
import { track } from 'apis/amethyst';
import { useIsEnrolledInVariant } from 'hooks/unleash/useIsEnrolledInVariant';
import { FEATURE_LANDING_PAGE_PAPER_CUTS_ONE } from 'constants/features';
import { convertProductLandingToProductWithRelations } from 'helpers/RecoUtils';

import css from 'styles/components/landing/melodyGrid.scss';

export const MelodyGrid = ({ slotName, slotIndex, slotDetails, onComponentClick, shouldLazyLoad, slotHeartsData, isHalf = false, eventLabel }) => {
  const { products, title, link, ctacopy, copy, subtext, bgcolor, gae, textcolor, image, alt, flip } = slotDetails;
  const heroCount = image ? 1 : 0;

  const formattedProducts = products.map(product => convertProductLandingToProductWithRelations(product));

  useEffect(() => {
    const defaultEventObj = { slotDetails, slotIndex, slotName, heroCount };
    const badgesEventObj = { formattedProducts, ...defaultEventObj };

    if (formattedProducts.length) {
      track(() => [evHeroWithProductStreamImpression, badgesEventObj]);
    }
  }, [heroCount, slotDetails, slotIndex, slotName, formattedProducts.length]);

  const { isEnrolled: isShowLandingPagePaperCutsFeature } = useIsEnrolledInVariant(FEATURE_LANDING_PAGE_PAPER_CUTS_ONE);
  const onHeroClick = useCallback(
    evt => {
      onComponentClick && onComponentClick(evt);
    },
    [heroCount, onComponentClick, slotDetails, slotIndex, slotName]
  );

  const makeTitle = () =>
    title && (
      <h2 className={css.heading} style={setInlineTextColor(textcolor)}>
        {title}
      </h2>
    );

  const makeCopy = () => copy && <p style={setInlineTextColor(textcolor)}>{copy}</p>;

  const makeSubText = () => subtext && <p className={css.subtext}>{subtext}</p>;

  const makeCallToAction = () => ctacopy && <p className={css.cta}>{ctacopy}</p>;

  const makeMainImage = () => image && <LandingPageImage src={image} alt={alt} shouldLazyLoad={shouldLazyLoad} className={css.lazyImg} />;

  const makeMainContent = () => {
    const styling = textcolor === 'white' ? css.mainContentCtaWhite : css.mainContentCtaDefault;
    const contents = (
      <div className={cn(css.mainContent, styling)} style={setInlineBackgroundColor(bgcolor)}>
        {makeTitle()}
        {makeCopy()}
        {makeSubText()}
        {makeCallToAction()}
        {makeMainImage()}
      </div>
    );
    const linkProps = {
      'onClick': onHeroClick,
      'data-eventlabel': eventLabel,
      'data-eventvalue': gae || title,
      'data-slotindex': slotIndex
    };
    return link ? (
      <LandingPageLink url={link} {...linkProps}>
        {contents}
      </LandingPageLink>
    ) : (
      contents
    );
  };

  return (
    <div className={cn({ [css.abTestPaperCutsContainer]: isShowLandingPagePaperCutsFeature })}>
      <div className={cn({ [css.containerFull]: isShowLandingPagePaperCutsFeature })}>
        <div className={cn({ [css.innerContainer]: isShowLandingPagePaperCutsFeature })}>
          <div className={cn(css.mProductGrid, { [css.flipped]: flip === 'true' }, { [css.isHalf]: isHalf })} data-slot-id={slotName}>
            <div className={css.mainContentContainer}>{makeMainContent()}</div>
            <div className={css.items}>
              <LandingProductCardWrapper
                slotDetails={slotDetails}
                slotHeartsData={slotHeartsData}
                componentStyling={css.melodyGridProducts}
                shouldLazyLoad={shouldLazyLoad}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary('MelodyGrid', MelodyGrid);
