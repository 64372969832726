import type { MouseEventHandler, ReactElement, RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import { isDesktop } from 'helpers/ClientUtils';
import { useWindowEvent } from 'hooks/useEvent';

interface ReturnProps {
  rightArrowClick: MouseEventHandler;
  leftArrowClick: MouseEventHandler;
  contentRef: RefObject<any>;
  arrowsVisible: boolean;
  scrollLeftDisabled: boolean;
  scrollRightDisabled: boolean;
}

interface Props {
  children(args: ReturnProps): ReactElement;
}

const Scroller = ({ children }: Props) => {
  const contentRef = useRef<HTMLElement>();
  const [arrowsVisible, setArrowsVisible] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const checkArrowVisiblity = () => {
    if (contentRef?.current) {
      const { scrollWidth, clientWidth } = contentRef.current;
      const maxScroll = scrollWidth - clientWidth;
      if (maxScroll) {
        setArrowsVisible(isDesktop());
      }
    }
  };

  useWindowEvent(
    'resize',
    useCallback(
      debounce(() => checkArrowVisiblity(), 200),
      []
    )
  );

  useEffect(() => {
    checkArrowVisiblity();
  }, []);

  const scroll = (isScrollingLeft: boolean, scrollTo: number) => {
    if (contentRef.current) {
      const {
        current: { scrollLeft }
      } = contentRef;
      let step = 20;
      const remainder = isScrollingLeft ? scrollLeft - scrollTo : scrollTo - scrollLeft;

      if (remainder < step) {
        step = remainder;
      }

      setIsScrolling(true);
      if ((isScrollingLeft && scrollLeft > scrollTo) || (!isScrollingLeft && scrollLeft < scrollTo)) {
        contentRef.current.scrollLeft += isScrollingLeft ? -step : step;
        setTimeout(scroll, 10, isScrollingLeft, scrollTo);
      } else {
        setIsScrolling(false);
      }
    }
  };

  const leftArrowClick = () => {
    if (!isScrolling && contentRef?.current) {
      const {
        current: { scrollLeft, clientWidth }
      } = contentRef;
      const scrollTo = scrollLeft - clientWidth > 0 ? scrollLeft - clientWidth : 0;
      setTimeout(scroll, 10, true, scrollTo);
    }
  };

  const rightArrowClick = () => {
    if (!isScrolling && contentRef?.current) {
      const {
        current: { scrollLeft, clientWidth, scrollWidth }
      } = contentRef;
      const maxScroll = scrollWidth - clientWidth;
      const scrollTo = scrollLeft + clientWidth < maxScroll ? scrollLeft + clientWidth : maxScroll;
      setTimeout(scroll, 10, false, scrollTo);
    }
  };

  const scrollLeftDisabled = () => {
    if (contentRef.current) {
      const {
        current: { scrollLeft }
      } = contentRef;
      return scrollLeft <= 0;
    }
    return false;
  };

  const scrollRightDisabled = () => {
    if (contentRef.current) {
      const {
        current: { scrollLeft, clientWidth, scrollWidth }
      } = contentRef;
      const maxScroll = scrollWidth - clientWidth;
      return scrollLeft === maxScroll;
    }
    return false;
  };

  return children({
    leftArrowClick,
    rightArrowClick,
    contentRef,
    arrowsVisible,
    scrollLeftDisabled: scrollLeftDisabled(),
    scrollRightDisabled: scrollRightDisabled()
  });
};

export default Scroller;
